<template>
  <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <van-cell v-for="(item, index) in list" :key="index" >
          <div>
            <p>
              <span>{{item.cost}}活跃值</span>
              <span>{{time(item.time)}}</span>
            </p>
            <p>
              <span>{{item.items[0].prizeAttr.cardDesc}}{{item.items[0].prizeAttr.duration}}天{{item.items[0].prizeAttr.total}}元</span>
              <span>收益加成卡</span>
            </p>
            <div></div>
          </div>
        </van-cell>
      </van-list>
      <div class="kong" v-if="list.length == 0">
        <img
          src="https://ppyos.xijiuyou.com/210915/fish-2.jpg"
          alt=""
        />
        <p>没有更多记录了</p>
      </div>
  </div>
</template>
<script>
import active from "../../api/active";
import dayjs from 'dayjs'
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page:1
    };
  },
  methods: {
    time(item){
      return dayjs(item).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    // 列表
    onLoad() {
      const that = this;
      active.exchangeHis(this.page).then((res) => {
        that.list.push(...res.list);
        that.loading = false;
        if (that.list.length >= res.count) {
          that.finished = true;
        }else{
          this.page++
        }
      });
    },
  },
};
</script>
<style scoped>
.content{
  min-height: 100vh;
  background: #ffffff;
}
.van-cell div div {
  height: 70px;
  display: flex;
  padding: 0 14px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  flex-wrap: wrap;
  position: relative;
}
.van-cell div p:nth-child(1) span {
  display: flex;
  justify-content: left;
}
.van-cell div p {
  width: 50%;
}
.van-cell div p span {
  display: flex;
  justify-content: flex-end;
}
.van-cell div div div {
  width: calc(100% - 28px);
  height: 1px;
  margin: 0 auto;
  background: #eeeeee;
  position: absolute;
  bottom: 0;
}
.van-cell div p span:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.van-cell div p:nth-child(2) span:nth-child(2) {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}
.van-cell div p span:nth-child(1) {
  font-size: 16px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #333333;
  white-space:nowrap;
}
.van-cell{
  padding: 0;
}
.kong {
  text-align: center;
}
.kong img {
  margin-top: 115px;
  width: 123px;
  height: 98px;
  margin-bottom: 24px;
}
.kong p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 21px;
}
.van-list__finished-text{
  display: none;
}
</style>